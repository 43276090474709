<template>
    <!-- 就餐服务-就餐学生 -->
    <repast-student-scroll-wrapper ></repast-student-scroll-wrapper>
</template>
<script>
import RepastStudentScrollWrapper from '@/components/scrollWrapper/RepastStudent.vue';

export default {
    name: "SServiceStuRepastStudent",
    components: {
        RepastStudentScrollWrapper,
    },

}
</script>
