<template>
    <!-- 就餐服务-就餐账单 -->
    <repast-bill-scroll-wrapper ></repast-bill-scroll-wrapper>
</template>
<script>
import RepastBillScrollWrapper from '@/components/scrollWrapper/RepastBill.vue';

export default {
    name: "SServiceStuRepastBill",
    components: {
        RepastBillScrollWrapper,
    },

}
</script>
