<template>
    <!-- 就餐服务-核查记录 -->
    <check-record-scroll-wrapper ></check-record-scroll-wrapper>
</template>
<script>
import CheckRecordScrollWrapper from '@/components/scrollWrapper/RepastCheckRecord.vue';
export default {
    name: "SServiceStuRepastCheckRecord",
    components: {
        CheckRecordScrollWrapper,
    },
}
</script>
